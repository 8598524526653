import config from '../config'
import clamp from 'lodash/fp/clamp'
import isNaN from 'lodash/isNaN'
import isNumber from 'lodash/isNumber'
import find from 'lodash/find'
import get from 'lodash/get'
import { getAllCompetencyFullModel } from '../apis/recruiter'

export const languageKeyParser = (originalText) => {
    return `LayoutSettings_QuestionSubType_${originalText.replace( /(\s+)(\S)/g, (_, whitespace, char) => char.toUpperCase())}`
}

export const getProgrammeAndJobName = (availableProgrammes, jobId) => {
    for (const programme of availableProgrammes) {
        for (const job of programme.job_list) {
            if (job.job_id === jobId) {
                return [programme.program_name, job.job_name,];
            }
        }
    }
    return ['', ''];
};

export const getProgramme = (availableProgrammes, jobId) => {
    for (const programme of availableProgrammes) {
        for (const job of programme.job_list) {
            if (job.job_id === jobId) {
                return programme;
            }
        }
    }
    return {}
};

export const getQuestionType = () => {
    return [{
        key: "LayoutSettings_QuestionType_General",
        value: "General HR & Employment"
    },
    {
        key: "LayoutSettings_QuestionType_SoftSkills",
        value: "Competency Soft Skills"
    },
    ]
};



export const getSimplifiedModelSubQuestionType = async () => {
    let competency_list = []
    return getAllCompetencyFullModel().then((res) => {
        competency_list = res.obj.filter((item) => item.model === 'simplified').map((item) => item.competency_name.en)
        // competency_list = res.obj.map((item) => item.competency_name.en)
        let competency_list_noDuplicate = [...new Set(competency_list)]
        competency_list_noDuplicate = competency_list_noDuplicate.map((item) => {
            return {
                key: languageKeyParser(item),
                value: item
            }
        })

        return {
            "General HR & Employment": [
                {
                    key: "LayoutSettings_QuestionSubType_Intro",
                    value: "Introduction"
                },
                {
                    key: "LayoutSettings_QuestionSubType_Motivation",
                    value: "Motivation & Career Plans"
                },
                {
                    key: "LayoutSettings_QuestionSubType_Strenth",
                    value: "Strength, Achievements & Weakness"
                },
                {
                    key: "LayoutSettings_QuestionSubType_Attitude",
                    value: "Attitude, Interest & Value"
                },
                {
                    key: "LayoutSettings_QuestionSubType_Understanding",
                    value: "Role/Company Understanding"
                },
                {
                    key: "LayoutSettings_QuestionSubType_WorkPlan",
                    value: "Work Plan"
                },
                {
                    key: "LayoutSettings_QuestionSubType_Salary",
                    value: "Salary"
                },
                {
                    key: "LayoutSettings_QuestionSubType_Others",
                    value: "Others"
                },
            ],
    
            "Competency Soft Skills": competency_list_noDuplicate
        }
        
    })
}

export const getFullModelSubQuestionType = async () => {
    let competency_list = []
    return getAllCompetencyFullModel().then((res) => {
        competency_list = res.obj.filter((item) => item.model === 'full').map((item) => item.competency_name.en)
        let competency_list_noDuplicate = [...new Set(competency_list)]
        competency_list_noDuplicate = competency_list_noDuplicate.map((item) => {
            return {
                key: languageKeyParser(item),
                value: item
            }
        })
        
        return {
            "General HR & Employment": [{
                key: "LayoutSettings_QuestionSubType_Intro",
                value: "Introduction"
            },
            {
                key: "LayoutSettings_QuestionSubType_Motivation",
                value: "Motivation & Career Plans"
            },
            {
                key: "LayoutSettings_QuestionSubType_Strenth",
                value: "Strength, Achievements & Weakness"
            },
            {
                key: "LayoutSettings_QuestionSubType_Attitude",
                value: "Attitude, Interest & Value"
            },
            {
                key: "LayoutSettings_QuestionSubType_Understanding",
                value: "Role/Company Understanding"
            },
            {
                key: "LayoutSettings_QuestionSubType_WorkPlan",
                value: "Work Plan"
            },
            {
                key: "LayoutSettings_QuestionSubType_Salary",
                value: "Salary"
            },
            {
                key: "LayoutSettings_QuestionSubType_Others",
                value: "Others"
            },
            ],
            "Competency Soft Skills": competency_list_noDuplicate
        }
    })
}

export const getQuestionSubType = async () => {
    return {
        "General HR & Employment": [{
            key: "LayoutSettings_QuestionSubType_Intro",
            value: "Introduction"
        },
        {
            key: "LayoutSettings_QuestionSubType_Motivation",
            value: "Motivation & Career Plans"
        },
        {
            key: "LayoutSettings_QuestionSubType_Strenth",
            value: "Strength, Achievements & Weakness"
        },
        {
            key: "LayoutSettings_QuestionSubType_Attitude",
            value: "Attitude, Interest & Value"
        },
        {
            key: "LayoutSettings_QuestionSubType_Understanding",
            value: "Role/Company Understanding"
        },
        {
            key: "LayoutSettings_QuestionSubType_WorkPlan",
            value: "Work Plan"
        },
        {
            key: "LayoutSettings_QuestionSubType_Salary",
            value: "Salary"
        },
        {
            key: "LayoutSettings_QuestionSubType_Others",
            value: "Others"
        },
        ],
        "Competency Soft Skills": [{
            key: "LayoutSettings_QuestionSubType_Leadership",
            value: "Leadership and Management"
        },
        {
            key: "LayoutSettings_QuestionSubType_Teamwork",
            value: "Teamwork and Service Oriented"
        },
        {
            key: "LayoutSettings_QuestionSubType_Communication",
            value: "Communication and Relationship Building"
        },
        {
            key: "LayoutSettings_QuestionSubType_ProblemAnalysis",
            value: "Understanding and Problem Analysis"
        },
        {
            key: "LayoutSettings_QuestionSubType_Strategy",
            value: "Strategy and Innovation"
        },
        {
            key: "LayoutSettings_QuestionSubType_Process",
            value: "Managing Process and Execution"
        },
        {
            key: "LayoutSettings_QuestionSubType_Change",
            value: "Change and Resilience"
        },
        {
            key: "LayoutSettings_QuestionSubType_Venturing",
            value: "Venturing and Achievement Oriented"
        },
        ]
    }
}

export const getColorSchemes = () => [{
    value: '#022D41',
    color: '#022D41'
}, {
    value: '#EF4044',
    color: '#EF4044'
}, {
    value: '#00A9BA',
    color: '#00A9BA'
}, {
    value: '#D5EDF3',
    color: '#D5EDF3'
}, {
    value: '#ACACAC',
    color: '#ACACAC'
}];

export const getLanguage = () => config.interviewLanguages.map(x => ({
    key: `InterviewLanguage_${mapLocale(x)}`,
    value: x,
}))


const languageShortForm = {
    "english": "en",
    "cantonese": "tc",
    "mandarin": "sc",
    "thai": "th",
    "japanese": "ja",
    "indonesian": "id",
    "malay": "ms",
    "vietnamese": "vi",
    "khmer": "km",
    "german": "de",
    "french": "fr",
    "korean": "kr",
    "tagalog": "ta",
}


// TODO:
// since there's no mapping between spoken and written language, the two
// should be separately used and stored
//   e.g. hk: ( cantonese, traditional chinese ),
//        mainland china: ( mandarin, simplified chinese),
//        taiwan: ( mandarin, traditional chinese )
export const mapLocale = language => config.languages.length <= 0 ? 'en' :
    config.interviewLanguages.length <= 0 ? 'en' :
        config.interviewLanguages.indexOf(language) === -1 ? config.languages[0] : languageShortForm[language]

export const scoringOptions = [{
    option: 'Recruiter_Scoring_Irrelevant',
    weight: 0,
}, {
    option: 'Recruiter_Scoring_Bonus',
    weight: 1,
}, {
    option: 'Recruiter_Scoring_Required',
    weight: 2,
}, {
    option: 'Recruiter_Scoring_Critical',
    weight: 3,
}
]

export const mapPercentageToScoreLevel = score => isNumber(score) && !isNaN(score) ?
    clamp(0, 3)(Math.floor(score * .04)) : 0

export const mapScoreLevelToPercentage = level => isNumber(level) && !isNaN(level) ?
    clamp(0, 100)(level * 33.3333) : 0

export const getWeightingText = (weighting) => {
    const scoreLevel = mapPercentageToScoreLevel(weighting)
    const option = find(scoringOptions, { weight: scoreLevel })
    return get(option, 'option', 'No relevant weighting')
}
