import { ErrorOutlined } from '@material-ui/icons';
import Swagger from 'swagger-client'
import uuidv1 from 'uuid/v1'
import config from '../config'
// import clientRequest from "../constants/clientRequest"


const swaggerSpec = `${config.baseUrl}/swagger.json`
const clientRequest = Swagger(swaggerSpec).then(client => {
    //   if (process.env.NODE_ENV === 'test') {
    //     client.spec.host = 'localhost:9003';
    //   }
    return client;
});
const tag2 = 'api/s3';

export const gets3object = (object_name, token, bucket_name) => {
    return new Promise((resolve, reject) => {
        const param = {
            "bucket_name": bucket_name,
            "object_name": object_name,
            "token": token
        }
        const request = {
            url: `${config.baseUrl}/api/s3/get_s3_object_url`,
            method: 'POST',
            headers: { 'Content-type': 'application/json' },
            body: JSON.stringify(param),
        };
        Swagger.http(request).then(
            res => {
                resolve(res.body)
            },
            err => {
                reject(err)
            })
    })
}

export const gets3objectWithJobId = (object_name, token, bucket_name) => {
    return new Promise((resolve, reject) => {
        const param = {
            "bucket_name": bucket_name,
            "object_name": object_name,
            "token": token
        }
        const request = {
            url: `${config.baseUrl}/api/s3/get_s3_object_url_unsafe`,
            method: 'POST',
            headers: { 'Content-type': 'application/json' },
            body: JSON.stringify(param),
        };
        Swagger.http(request).then(
            res => {
                resolve(res.body)
            },
            err => {
                reject(err)
            })
    })
}


export const getMultipleObject = (interview_id, token) => {
    return new Promise((resolve, reject) => {
        const param = {
            "interview_id": interview_id,
        }
        const request = {
            url: "https://iudn371087.execute-api.ap-southeast-1.amazonaws.com/prod",
            //url: `localhost:8000/api/s3/download_multiple_object`,
            method: 'GET',
            query: param,
            headers: {
                'x-api-key': 'A3hrrQM8Bo7kHSlBwGfGH2MOL3RxWp6S7vHInVvq',

            },

        };

        Swagger.http(request).then(
            res => {
                resolve(res.body)
            },
            err => {
                reject(err)
            }
        )
    })
}

export const posts3Object = (object_name, token, progressBack) => {
    return clientRequest.then(client => {
        const param = {
            "object_name": object_name,
            "token": token
        }
        const request = {
            url: `${config.baseUrl}/api/s3/create_s3_upload_url`,
            method: 'POST',
            headers: { 'Content-type': 'application/json' },
            body: JSON.stringify(param),
        };
        Swagger.http(request).then(res => {
            progressBack(res)
        })
    })
}

export const getExists3File = (object_name, token) => {
    return new Promise((resolve, reject) => {
        gets3object(object_name, token).then(res => {
            var xmlHttp = new XMLHttpRequest();
            xmlHttp.open("GET", res.body, false); // false for synchronous request
            xmlHttp.send();
            if (xmlHttp.status == 200) {
                resolve(res.body)
            } else {
                reject(xmlHttp)
            }
        },
            err => {
                reject(err)
            })
    })
}

export const create_upload = (object_name, token, bucket_name) => {
    return new Promise((resolve, reject) => {
        const param = {
            "bucket_name": bucket_name,
            "object_name": object_name,
            "token": token
        }
        const request = {
            url: `${config.baseUrl}/api/s3/generate_upload_id`,
            method: 'POST',
            headers: { 'Content-type': 'application/json' },
            body: JSON.stringify(param),
        };
        Swagger.http(request).then(res => {
            resolve(res)
        }, err => {
            reject(err)
        })

    }
    )
}

export const multi_upload = (object_name, upload_id, part_no, token, bucket_name) => {
    return new Promise((resolve, reject) => {
        const param = {
            "bucket_name": bucket_name,
            "object_name": object_name,
            "upload_id": upload_id,
            "part_no": part_no,
            "token": token
        }
        const request = {
            url: `${config.baseUrl}/api/s3/upload_multipart_s3_object`,
            method: 'POST',
            headers: { 'Content-type': 'application/json' },
            body: JSON.stringify(param),
        };
        Swagger.http(request).then(res => {
            resolve(res)
        }, err => {
            reject(err)
        })

    })
}

export const complete_upload = (object_name, upload_id, parts, token, bucket_name, candidate) => {
    return new Promise((resolve, reject) => {
        const param = {
            "candidate": candidate === "yes" ? "yes" : "no",
            "bucket_name": bucket_name,
            "object_name": object_name,
            "parts": parts,
            "token": token,
            "upload_id": upload_id
        }
        const request = {
            url: `${config.baseUrl}/api/s3/complete_multipart_s3_upload`,
            method: 'POST',
            headers: { 'Content-type': 'application/json' },
            body: JSON.stringify(param),
        };
        // console.log(request, "_____+++++======+_________")
        Swagger.http(request).then(res => {
            resolve(res)
        }, err => {
            reject(err)
        })

    })
}

export const s3_upload_object_url = (token, bucket_name, object_name, blob) => {
    return new Promise((resolve, reject) => {
        var data = new FormData()
        data.append("token",token)
        data.append("bucket_name",bucket_name)
        data.append("object_name",object_name)
        data.append("object_file",blob)
        const request = {
            url: `${config.baseUrl}/api/s3/put_s3_object_url`,
            method: 'POST',
            body: data
        };
        Swagger.http(request).then(res => {
            resolve(res)
        }, err => {
            reject(err)
        })

    })
}

export const create_s3_upload_url_simple = (bucket_name, object_name, token, content_type = null) => {
    return new Promise((resolve, reject) => {
        var params = {}
        if (content_type != null) {
            params = {
                "bucket_name": bucket_name,
                "object_name": object_name,
                "token": token,
                "content_type": content_type,
            }
        } else {
            params = {
                "bucket_name": bucket_name,
                "object_name": object_name,
                "token": token,
            }
        }

        const request = {
            url: `${config.baseUrl}/api/s3/create_s3_upload_url_simple`,
            method: 'POST',
            headers: { 'Content-type': 'application/json' },
            body: JSON.stringify(params),
        };
        Swagger.http(request).then((res) => {
            resolve(res.body)
        }, (err) => {
            reject(err)
        })
    })
}

const s3access = {
    gets3object,
    posts3Object,
    getExists3File,
    create_upload,
    multi_upload,
    complete_upload,
    create_s3_upload_url_simple,
    s3_upload_object_url
};

export default s3access;
