import {
    createSlice
} from 'redux-starter-kit'
import {
    addProgram,
    deleteJobs,
    fetchStatus
} from '../apis/recruiter'
import {
    getAllStat,
    getStat,
} from '../apis/statistics'
import _ from 'lodash';
import { gets3object } from '../apis/s3access'
import { BUCKETS } from '../constants/constants';

/**
 - profile: {
    fistName: '',
    lastName: '',
 }
 - availableProgrammes:
 [
 {
        "job_list": [
            {
                "job_id": "developer_20190623",
                "job_name": "developer"
            },
            {
                "job_id": "developer_20190620",
                "job_name": "developer"
            },
            {
                "job_id": "developer_20190623",
                "job_name": "developer"
            },
            {
                "job_id": "developer_20191022163812",
                "job_name": "developer"
            }
        ],
        "program_name": "finance & legal program"
      },
 ]
 - selectedJobId: "developer_20190623"
**/

let initialState = {
    profile: {
        firstName: '',
        lastName: '',
        aiRequired: 1,
        videoAnnotationRequired: 1,
        roles: [],
    },
    availableProgrammes: [],
    selectedJobId: null,
    selectedJobName: null,
    programmeLoaded: false,
    listAvailableProgrammeInProgress: false,
    getProgrammeStatisticsInProgress: false,
    addingProgram: false,
    deleteJobsInProgress: false,
    requestIds: [],
    group:{
        gid:"",
        groupName:"",
    },

};

const programmeSlice = createSlice({
    slice: 'programme',
    initialState: initialState,
    reducers: {
        selectJob(state, action) {
            state.selectedJobId = action.payload.jobId;
            state.selectedJobName = action.payload.jobName;
            state.group = action.payload?.group
        },
        addRequestId(state, action) {
            state.requestIds = state.requestIds.concat(action.payload)
        },
        removeRequestId(state, action) {
            state.requestIds = []
        },
        onListAvailableProgrammes(state, action) {
            state.loadingAvailableProgramme = true;
        },
        onProfileSuccess(state, action) {
            state.profile = action.payload;
        },
        onListAvailableProgrammesSuccess(state, action) {
            state.availableProgrammes = action.payload.map(programme => {
                programme.job_list = _.uniqBy(programme.job_list, 'job_id');
                programme.job_list.forEach(job => {
                    job.program_name = programme.program_name;
                    if (!job.created_at) {
                        job.created_at = job.job_id.split("_")[1].slice(0, 4) + "-" + job.job_id.split("_")[1].slice(4, 6) + "-" + job.job_id.split("_")[1].slice(6, 8)
                    }
                });
                return programme;
            });
            if (action.payload.length > 0) {
                const selectedProgramme = action.payload[0];
                if (selectedProgramme.job_list.length > 0) {
                    if (state.selectedJobId === null) {
                        state.selectedJobId = selectedProgramme.job_list[0].job_id;
                        state.selectedJobName = selectedProgramme.job_list[0].job_name;
                    }
                }
            }
            state.loadingAvailableProgramme = false;
            state.programmeLoaded = true;
        },
        onListAvailableProgrammesFailed(state, action) {
            state.loadingAvailableProgramme = false;
        },
        onGetProgrammeStatistics(state, action) {
            state.getProgrammeStatisticsInProgress = true;
        },
        onGetProgrammeStatisticsSuccess(state, action) {
            action.payload.forEach((programStat) => {
                state.availableProgrammes.forEach((programme) => {
                    const found = programme.job_list.find((job) => {
                        return job.job_id === programStat.jobId;
                    });
                    if (found) {
                        delete programStat.deadline;
                        Object.assign(found, programStat);
                    }
                });
            });
            state.getProgrammeStatisticsInProgress = false;
        },
        onGetProgrammeStatisticsFailed(state, action) {
            state.getProgrammeStatisticsInProgress = false;
            state.errorMessage = action.payload;
        },
        onAddProgram(state, action) {
            state.addingProgram = true;
        },
        onAddProgramSuccess(state, action) {
            state.addingProgram = false;
            const selectedProgramme = state.availableProgrammes.find((programme) => {
                return programme.program_name === action.payload.program.program;
            });
            if (selectedProgramme) {
                selectedProgramme.job_list.push({
                    job_id: action.payload.jobId,
                    job_name: action.payload.program.job_name,
                    program_name: action.payload.program.program,
                    test: action.payload.program.test
                })
            } else {
                state.availableProgrammes.push({
                    "job_list": [{
                        job_id: action.payload.jobId,
                        job_name: action.payload.program.job_name,
                        program_name: action.payload.program.program,
                        test: action.payload.program.test
                    }, ],
                    "program_name": action.payload.program.program
                })
            }
            state.programmeLoaded = false
        },
        onAddProgramFailed(state, action) {
            state.addingProgram = false;
        },

        onRemoveJobSetting(state, action) {
            state.deleteJobsInProgress = true;
        },
        onRemoveJobSettingSuccess(state, action) {
            state.availableProgrammes.forEach((programme) => {
                programme.job_list = programme.job_list.filter((job) => {
                    return action.payload.find((deleteJob) => {
                        return deleteJob.jobId === job.jobId;
                    }) === undefined
                });
            });
            state.deleteJobsInProgress = false;
        },
        onRemoveJobSettingFailed(state, action) {
            state.deleteJobsInProgress = false;
        },
        clearProgrammeStatistics(state, action) {
            action.payload.forEach((jobId) => {
                state.availableProgrammes.forEach((programme) => {
                    const found = programme.job_list.find((job) => {
                        return job.job_id === jobId;
                    });
                    if (found) {
                        delete found.numOfCandidates;
                        delete found.noOfQuestion;
                    }
                });
            });
        },

    }
});

export const {
    selectJob,
    addRequestId,
    removeRequestId,
    onListAvailableProgrammes,
    onListAvailableProgrammesSuccess,
    onListAvailableProgrammesFailed,
    onProfileSuccess,
    onGetProgrammeStatistics,
    onGetProgrammeStatisticsSuccess,
    onGetProgrammeStatisticsFailed,

    onAddProgram,
    onAddProgramSuccess,
    onAddProgramFailed,

    onRemoveJobSetting,
    onRemoveJobSettingSuccess,
    onRemoveJobSettingFailed,

    clearProgrammeStatistics,
} = programmeSlice.actions;

export default programmeSlice.reducer;


/**
 *
 * @param {String} token
 */


export const onSelectJob = (selectedjob) => async dispatch => {
    console.log("selectedjob: ", selectedjob)
    dispatch(selectJob(selectedjob));
}

export const listAvailableProgrammes = (token) => async dispatch => {
    dispatch(onListAvailableProgrammes());
    try {
        const res = await fetchStatus(token);
        if (res.ok && res.obj && res.obj.error_code === 0 && res.obj.result) {
            const programs = res.obj.result.program || [];
            for (var program of programs) {
                for (var job of program.job_list) {
                    job.deadline = job.deadline ? job.deadline.split(" ")[0] : "-"
                }
            }

            const result = res.obj.result

            const profile = {
                email: result.email ? result.email : '',
                recruiterId: result.recruiter_id ? result.recruiter_id : '',
                firstName: result.first_name ? result.first_name : '',
                lastName: result.last_name ? result.last_name : '',
                jobTitle: result.job_title ? result.job_title : '',
                phoneNum: result.phone_num ? result.phone_num : '',
                aiRequired: res.obj.result.ai_required === 0 ? 0 : 1,
                addFile: res.obj.result.add_file === 0 ? 0 : 1,
                shareReport: res?.obj?.result?.share_pdf_report ? 1 : 0,
                reportType: res?.obj?.result?.pdf_report_type === "withscore" ? "with_score" : "without_score",
                evaluationRequired: res.obj.result.evaluation_required === 0 ? 0 : 1,
                companyName: result.company_name ? result.company_name : '',
                timezone: result.timezone ? result.timezone : '',
                timezoneArea: result.timezone_area ? result.timezone_area : null,
                companyWebsite: result.company_website ? result.company_website : '',
                //profilePicture: result.profile_picture ? `/api/recruiter/get_profile_pic/${result.profile_picture}` : null,
                profilePicture: result.profile_picture ? await gets3object(result.profile_picture, token, BUCKETS.MEDIA_BUCKET) : null,
                // companyVideo: result.company_video ? `/api/recruiter/get_company_video/${result.company_video}` : null,
                companyVideo: result.company_video ? await gets3object(result.company_video, token, BUCKETS.MEDIA_BUCKET) : null,
                companyLogo: result.company_logo ? await gets3object(result.company_logo, token, BUCKETS.MEDIA_BUCKET) : null,
                register_date: result.register_date,
                videoAnnotationRequired: result.video_annotation_required === 0 ? 0 : 1,
                colorBlindTest: result.color_blind_test ? result.color_blind_test : 0,
                superRecruiter: result.super_recruiter ? result.super_recruiter : '-',
                roles: result.roles,

                sortQa: result?.sort_qa ||0,
                mcq: result?.mcq || 0,
                videoInterView: result?.video_interview == undefined ? 1:result?.video_interview,
                interviewModel: result?.video_interview_model,
                source:result?.source||false,
                sapCompanyUrl:result?.source == "sap"?result?.sapCompanyUrl.replace("api",""):"",
                sapCompanyId:result?.source == "sap"?result?.sapUsername.split("@")[1]:"",
                sapUsername:result?.source == "sap"?result?.sapUsername.split("@")[0]:"",
                sapPassword:result?.source == "sap"?result?.sapPassword:"",
                sapStatusId: result?.sapStatusId || "",
                exportedFiles : result?.excelFileStatus || [],

                developerModeEnabled: result?.developerModeEnabled || false,
                developerAPIKey: result?.api_key || null,
            };

            dispatch(onProfileSuccess(profile));
            dispatch(onListAvailableProgrammesSuccess(programs));
            return programs;
        }
    } catch (err) {
        console.log(err);
        dispatch(onListAvailableProgrammesFailed({
            message: err.toString()
        }));
        return false;
    }
};

export const getProgrammeStatistics = (jobIds, token) => async dispatch => {
    dispatch(onGetProgrammeStatistics());
    try {
        const statResponses = await getAllStat(token);
        const payload = [];
        if (statResponses.ok && statResponses.obj && statResponses.obj.result) {
            statResponses.obj.result.forEach((statResponse) => {
                payload.push({
                    jobId: statResponse.job_id,
                    numOfCandidates: statResponse.total_candidates,
                    deadline: statResponse.interview_deadline.split(" ")[0] || "-",
                    completion: statResponse.completed_candidates,
                    reviewed: statResponse.ai_reviewed_candidates,
                    questionNum: statResponse.num_of_question,
                    completionRate: statResponse.total_candidates && statResponse.total_candidates > 0 ? Math.round(statResponse.ai_reviewed_candidates / statResponse.total_candidates * 100) : 0,
                    // completionRate: statResponse.total_candidates && statResponse.total_candidates > 0 ? Math.round(statResponse.completed_candidates / statResponse.total_candidates * 100) : 0
                });
            });
        }
        dispatch(onGetProgrammeStatisticsSuccess(payload));
    } catch (e) {
        dispatch(onGetProgrammeStatisticsFailed(e.message));
    }

};

/**
 *
 * @param {String} program_name
 * @param {String} job_title
 * @param {String} token
 */
export const addNewProgramme = (program_name, job_title, token, jobIdToCopy, test, group) => async dispatch => {
    dispatch(onAddProgram());
    const program = {
        job_name: job_title,
        program: program_name,
        test: test,
        group: group
    };
    try {
        const res = await addProgram(program, token, jobIdToCopy);
        if (res.ok && res.obj && res.obj.error_code === 0) {
            const jobId = res.obj.job_id;
            dispatch(onAddProgramSuccess({
                program,
                jobId
            }));
            program.job_id = jobId;
            return program;
        } else {
            dispatch(onAddProgramFailed({
                message: 'Add Program Failed with response ' + res.obj.error_code
            }));
            return false;
        }
    } catch (err) {
        console.log(err);
        dispatch(onAddProgramFailed({
            message: err.toString()
        }));
        return false;
    }
};

/**
 *
 * @param {String} job_id
 * @param {String} token
 */
export const removeJobSetting = (jobs, token) => async (dispatch, getState) => {
    dispatch(onRemoveJobSetting());
    try {
        const res = await deleteJobs(jobs, token);
        if (res.ok && res.obj && res.obj.error_code === 0) {
            dispatch(onRemoveJobSettingSuccess(jobs));
            return true;
        } else {
            console.log("Error with api call (fetchSetting)");
            dispatch(onRemoveJobSettingFailed());
            return false;
        }
    } catch (err) {
        console.log(err);
        dispatch(onRemoveJobSettingFailed({
            message: err.toString()
        }));
        return false;
    }
};
