import { create_s3_upload_url_simple, gets3object } from '../apis/s3access'
import axios from 'axios'


export const upload_single_object_to_s3 = async (bucket_name, obj, object_name, token, content_type = null) => {

    try {
        const res = await create_s3_upload_url_simple(bucket_name, object_name, token, content_type)

        if (res.errors === undefined) {
            const formData = new FormData()
            Object.entries(res.fields).forEach(([key, values]) => {
                formData.append(key, values);
            })

            formData.append('file', obj, object_name)
            try {
                const response = await axios.post(res.url, formData, {
                    headers: { 'Content-Type': 'multipart/form-data' },
                })
                return response
            } catch (err) {
                console.log(err)
            }
        } else {
            console.log("Error with api call")
        }
    } catch (err) {
        console.log(err)
    }
}





export const get_interviewerImage = async (object_name, token, bucket_name) => {
    const res = await gets3object(object_name, token, bucket_name)
    return res
}



