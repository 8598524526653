import Swagger from 'swagger-client'
import uuidv1 from 'uuid/v1'
import config from '../config'
import clientRequest from "../constants/clientRequest"


const tag2 = 'api/tasks';

export const uploadVideo = (token, question, job_id, tot, num, interview_id, finish, interview_language, answer) => {
    return clientRequest.then(client => {
        let param = {
            url: `${config.baseUrl}/api/user_info/ip_location/${token}`,
            method: 'GET',
        }
        Swagger.http(param)
        .then(res => {
            res = res.body
            let payload_data = {
                "token": token,
                "question": question,
                "job_id": job_id,
                "tot": tot, 
                "num": num,
                "interview_id": interview_id,
                "finish": finish ? 'yes' : 'no',
                "interview_language": interview_language,
                "text_answer": answer,
                "browser": res.browser || "",
                "city": res.city || "",
                "country": res.country || "",
                "ip": res.ip || "",
                "lat": res.latitude || 0,
                "lng": res.longitude || 0,
                "more": res.more || "",
                "os": res.os || "",
                "region": res.region || ""

            }
            const request = {
                url: `${config.baseUrl}/api/tasks/btob_video`,
                method: 'POST',
                headers: { 'Content-type': 'application/json' },
                body: JSON.stringify(payload_data),
            };
            return Swagger.http(request)
        })
        .catch(error => {
            console.error("sendToBtob: error: ", error)
            let payload_data = {
                "token": token,
                "question": question,
                "job_id": job_id,
                "tot": tot, 
                "num": num,
                "interview_id": interview_id,
                "finish": finish ? 'yes' : 'no',
                "interview_language": interview_language,
                "text_answer": answer,
                "browser": "",
                "city": "",
                "country": "",
                "ip": "",
                "lat": 0,
                "lng": 0,
                "more": "",
                "os": "",
                "region": ""
            }
            const request = {
                url: `${config.baseUrl}/api/tasks/btob_video`,
                method: 'POST',
                headers: { 'Content-type': 'application/json' },
                body: JSON.stringify(payload_data),
            };
            return Swagger.http(request)
        })

    });
};


// export const uploadVideo = (video, token, question, job_id, tot, num, interview_id, finish, interview_language, answer) => {
//     return clientRequest.then(client => {
//         const formData = new FormData();
//         console.log(video)
//         formData.append("video", video, `real-${job_id}-${uuidv1()}.webm`);
//         formData.append("token", token);
//         formData.append("question", question);
//         formData.append("job_id", job_id);
//         formData.append("tot", tot);
//         formData.append("num", num);
//         formData.append("interview_id", interview_id);
//         formData.append("finish", finish ? 'yes' : 'no');
//         formData.append("interview_language", interview_language);
//         formData.append("text_answer", answer);
//         const request = {
//             url: `${config.baseUrl}/api/tasks/btob_video`,
//             method: 'POST',
//             body: formData,
//         };
//         return Swagger.http(request)
//     });
// };

export const uploadPractice = (token, question, job_id, interview_language) => {
    return clientRequest.then(client => {
        // Tags Interface
        const formData = new FormData();
        // formData.append("video", video, `practice-${job_id}-${uuidv1()}.webm`);
        formData.append("token", token);
        formData.append("question", question);
        formData.append("job_id", job_id);
        formData.append("interview_language", interview_language);
        const request = {
            url: `${config.baseUrl}/api/tasks/practice_video`,
            method: 'POST',
            body: formData,
        };
        return Swagger.http(request)
    })
};

export const uploadTest = (video, token) => {
    const formData = new FormData();
    formData.append("file", video, `test-${uuidv1()}.webm`);
    formData.append("token", token);
    const request = {
        url: `${config.baseUrl}/api/tasks/upload_video`,
        method: 'POST',
        body: formData,
    };
    return Swagger.http(request);
};

export const getTask = (task_id) => {
    return clientRequest.then(client => {
        // Tags Interface
        return client.apis[tag2].get_get_task({
            task_id: task_id,
        })
    })
}

export const check = p => clientRequest.then(client => client.apis[tag2].get_check(p))

export const checkUplink = p => {
    const formData = new FormData()
    for (let k in p) {
        formData.append(k, p[k])
    }
    const request = {
        url: `${config.baseUrl}/api/tasks/check`,
        method: 'POST',
        body: formData,
    }
    return Swagger.http(request)
}

export const sendLog = (token, log) => {
    return clientRequest
        .then(client => {
            // Tags interface
            return client.apis[tag2].post_get_log({
                payload: {
                    token,
                    log
                }
            });
        })
};

const tasks = {
    uploadVideo,
    uploadPractice,
    uploadTest,
    check,
    checkUplink,
    sendLog
};

export default tasks;
