import { LogLevel } from "@azure/msal-browser";
const ua = window.navigator.userAgent;
const msie = ua.indexOf("MSIE ");
const msie11 = ua.indexOf("Trident/");
const msedge = ua.indexOf("Edge/");
const firefox = ua.indexOf("Firefox");
const isIE = msie > 0 || msie11 > 0;
const isEdge = msedge > 0;
const isFirefox = firefox > 0;

export const setMsalConfig = async() => {
    const apiReponse = await fetch('https://adfs-uat.polyu.edu.hk/adfs/.well-known/openid-configuration')
    console.log("MSAL.js metadata resonse: ", apiReponse)
    const apiResponseJson = await apiReponse.json()
    console.log("MSAL.js metadata resonse json: ", apiResponseJson)
    // const metadata = apiResponseJson.metadata
    // console.log("MSAL.js metadata resonse metadata: ", metadata)
    return {
        ...defaultMsalConfig,
        authorityMetadata : apiResponseJson
    }
}

export const defaultMsalConfig = {
    auth: {
        // clientId: "45ce08ce-969e-4fb7-9706-77247c20d281",
        clientId: "1dc3c3e2-2a38-43a5-b8a8-4edf93496c21",
        authority: "https://login.microsoftonline.com/common/",
        knownAuthorities: [
            "https://login.microsoftonline.com/b3e19ac2-e192-44c8-90bd-41acbfb3dcdb/oauth2/v2.0/authorize",
            "https://adfs-uat.polyu.edu.hk/adfs", 
            "https://adfs-uat.polyu.edu.hk/adfs/oauth2/authorize/",
            "https://adfs.polyu.edu.hk/adfs/oauth2/authorize/",
        ],
        redirectUri: "https://interview.neufast.com/sso",
        // redirectUri: "http://localhost:3000/sso",
        // cloudDiscoveryMetadata: "https://adfs-uat.polyu.edu.hk/adfs/.well-known/openid-configuration",
        protocolMode: "OIDC",
        postLogoutRedirectUri: "/",
    },
    cache: {
        cacheLocation: "localStorage",
        // storeAuthStateInCookie: false,
        storeAuthStateInCookie: isIE,
        secureCookies: true,
    },
    system: {	
        loggerOptions: {	
            loggerCallback: (level, message, containsPii) => {	
                if (containsPii) {		
                    return;		
                }		
                switch (level) {
                    case LogLevel.Error:
                        console.error(message);
                        return;
                    case LogLevel.Info:
                        console.info(message);
                        return;
                    case LogLevel.Verbose:
                        console.debug(message);
                        return;
                    case LogLevel.Warning:
                        console.warn(message);
                        return;
                    default:
                        return;
                }	
            }	
        }	
    },
}
export const loginRequest = {
    // scopes: ["openid", "email", "profile"],
    scopes: ["User.Read"],
};

export const graphConfig = {
    graphMeEndpoint: "https://graph.microsoft.com/v1.0/me",
    // graphMeEndpoint: "https://adfs-uat.polyu.edu.hk/adfs/oauth2/token/",
    graphMailEndpoint: "https://graph.microsoft.com/v1.0/me/messages",
};